

/* ----------------------------------------
        Imports
    ---------------------------------------- */

import { Component, Prop, Vue } from 'vue-property-decorator';
import ErrorResponseMixin from '@mixin/errorResponse';
import UserAccountFields from '@part/forms/UserAccountFields';
import UserTermsFields from '@part/forms/UserTermsFields';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
    data() {
        return {
            post: {
                email: '',
                password: '',
                terms: false,
                newsletter: false
            }
        };
    },

    mixins: [
        ErrorResponseMixin,
    ],

    components: {
        UserAccountFields,
        UserTermsFields,
    },

    methods: {
        submit() {
            //backend needs 0 / 1 values for checkboxes
            this.post.newsletter == true ? (this.post.newsletter) = 1 : (this.post.newsletter = 0);
            this.post.terms == true ? (this.post.terms) = 1 : (this.post.terms = 0);

            this.$store.dispatch('user/register', {
                ...this.post
            }).then(response => {
                if (response instanceof Error) {                    
                    if (response.response.status == 422){
                        this.$store.dispatch('setToastMessage', this.getErrorMessage(response))
                        this.$store.dispatch('setToastButton', '');
                    } else {
                        this.$store.dispatch('setToastMessage', response.response.data.message)
                        this.$store.dispatch('setToastButton', '');
                    }
                }
            });
        }
    },
})

/* ----------------------------------------
    Export
---------------------------------------- */

export default class RegisterForm extends Vue {}

