

/* ----------------------------------------
        Imports
    ---------------------------------------- */

import { Component, Prop, Vue } from 'vue-property-decorator';

/* ----------------------------------------
        Component
    ---------------------------------------- */

    @Component({
        
    })

/* ----------------------------------------
        Export
    ---------------------------------------- */

export default class UserDataFields extends Vue {
        @Prop(Object) value!: object

        @Prop() legend!: string
};

