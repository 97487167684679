

/* ----------------------------------------
        Imports
    ---------------------------------------- */

import { Component, Mixins, Vue } from 'vue-property-decorator';
import { SlotsMixin } from '@fruitpackage/mixins';

/* ----------------------------------------
        Component
    ---------------------------------------- */

    @Component({
        mixins: [
            SlotsMixin, 
        ],
        transition: {
            enter: (data, transition) => {
                const { el, parent } = data;
                console.log(el);
                console.log('ENTER');
            },
            update: (data, transition) => {
                const { el, parent } = data;
                console.log(el);
                console.log('UPDATE');
            },
            leave: (data, transition) => {
                const { el, parent } = data;
                console.log(el);
                console.log('LEAVE');
            }
        },
    })

/* ----------------------------------------
        Export
    ---------------------------------------- */

export default class PageMain extends Vue {};

