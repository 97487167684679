/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({

    data() {
        return {
            errorData: null,
        };
    },

    methods: {
        getErrorMessage(response) {
            const errors = response.response.data.errors;
            if (errors == null){
                return null;
            }
            return errors[Object.keys(errors)[0]][0];
        }
    }
})

/* ----------------------------------------
    Export
---------------------------------------- */

export default class ErrorResponseMixin extends Vue {}